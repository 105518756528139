:focus {
  outline: none;
}

ul {
  list-style: none;
}

/* ------------------------------------------ */
.App {
  font-size: 1em;
}

.box1 {
  position: relative;
  width: 272px;
  height: 2.2em;
  margin: 2px;
  border-bottom: 1px solid black;
  border-radius: 0.3em;
  background-color: rgba(230,230,240,0.5);
}

.textLine1 {
  position: absolute;
  left: 2%;
  bottom: 0;
  z-index: 100;
  height: 50%;
  width: 96%;
  padding: 0em 0em;
  border: none;
  background-color: transparent;
  font-size: 1em;
}

.box2 {
  position: relative;
  width: 272px;
  height: 10em;
  margin: 2px;
  border-bottom: 1px solid black;
  border-radius: 0.3em;
  background-color: rgba(230,230,240,0.5);
}

.textArea1 {
  position: absolute;
  left: 2%;
  bottom: 0;
  z-index: 100;
  height: 90%;
  width: 96%;
  resize: none;
  padding: 0em 0em;
  border: none;
  background-color: transparent;
  font-size: 1em;
}

.box3 {
  position: relative;
  width: 272px;
  height: 3em;
  margin: 2px;
  border-bottom: 1px solid black;
  border-radius: 0.3em;
  background-color: rgba(230,230,240,0.5);
}

.box4 {
  position: relative;
  width: 600px;
  height: 3em;
  margin: 2px;
  border-bottom: 1px solid black;
  border-radius: 0.3em;
  background-color: rgba(230,230,240,0.5);
}

.textArea2 {
  position: absolute;
  left: 2%;
  bottom: 0;
  z-index: 100;
  height: 70%;
  width: 96%;
  resize: none;
  white-space: pre;
  overflow-wrap: normal;
  overflow-x: auto;
  padding: 0em 0em;
  border: none;
  background-color: transparent;
  font-size: 1em;
}

.button1 {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #c0d0ff;
  cursor: pointer;
}

.button1:focus {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #d0e0f0;
  cursor: pointer;
}

.button1:active {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.5em;
  background-color: #d0e0f0;
  cursor: pointer;
}

.button1Dummy {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: transparent;
}

.button1Asset {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #ffd0c0;
  cursor: pointer;
}

.button1Asset:focus {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #f0e0d0;
  cursor: pointer;
}

.button1Asset:active {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.5em;
  background-color: #f0e0d0;
  cursor: pointer;
}

.button1Monacard {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #ffffb0;
  cursor: pointer;
}

.button1Monacard:focus {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #f0f0c0;
  cursor: pointer;
}

.button1Monacard:active {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.5em;
  background-color: #f0f0c0;
  cursor: pointer;
}

.button1Transaction {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #9090ff;
  cursor: pointer;
}

.button1Transaction:focus {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #a0a0f0;
  cursor: pointer;
}

.button1Transaction:active {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.5em;
  background-color: #a0a0f0;
  cursor: pointer;
}

.button2 {
  height: 3.8em;
  width: 6em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #c0d0ff;
  cursor: pointer;
}

.button2:focus {
  height: 3.8em;
  width: 6em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #d0e0f0;
  cursor: pointer;
}

.button2:active {
  height: 3.8em;
  width: 6em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.5em;
  background-color: #d0e0f0;
  cursor: pointer;
}

.button3 {
  height: 3.5em;
  width: 280px;
  margin: 0.5em;
  padding: 0;
  border: 1px solid rgba(256,256,256,0);
  border-radius: 0.3em;
  background-color: #c0d0ff;
  cursor: pointer;
}

.button3:focus {
  height: 3.5em;
  width: 280px;
  margin: 0.5em;
  padding: 0;
  border: 1px solid rgba(256,256,256,0);
  border-radius: 0.3em;
  background-color: #d0e0f0;
  cursor: pointer;
}

.button3:active {
  height: 3.5em;
  width: 280px;
  margin: 0.5em;
  padding: 0;
  border: 1px solid rgba(256,256,256,0);
  border-radius: 0.5em;
  background-color: #d0e0f0;
  cursor: pointer;
}

.button3Double {
  height: 3.5em;
  width: 560px;
  margin: 0.5em;
  padding: 0;
  border: 1px solid rgba(256,256,256,0);
  border-radius: 0.3em;
  background-color: #c0d0ff;
  cursor: pointer;
}

.button3Double:focus {
  height: 3.5em;
  width: 560px;
  margin: 0.5em;
  padding: 0;
  border: 1px solid rgba(256,256,256,0);
  border-radius: 0.3em;
  background-color: #d0e0f0;
  cursor: pointer;
}

.button3Double:active {
  height: 3.5em;
  width: 560px;
  margin: 0.5em;
  padding: 0;
  border: 1px solid rgba(256,256,256,0);
  border-radius: 0.5em;
  background-color: #d0e0f0;
  cursor: pointer;
}

.button4 {
  height: 3.5em;
  width: 280px;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #ffd0c0;
  cursor: pointer;
}

.button4:focus {
  height: 3.5em;
  width: 280px;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #f0e0d0;
  cursor: pointer;
}

.button4:active {
  height: 3.5em;
  width: 280px;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.5em;
  background-color: #f0e0d0;
  cursor: pointer;
}

.button5 {
  height: 3.5em;
  width: 20em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #ffffb0;
  cursor: pointer;
}

.button5:focus {
  height: 3.5em;
  width: 20em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: #f0f0c0;
  cursor: pointer;
}

.button5:active {
  height: 3.5em;
  width: 20em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.5em;
  background-color: #f0f0c0;
  cursor: pointer;
}

.button7false {
  margin: 0.5em;
  padding: 0.3em;
  border: none;
  border-radius: 0.3em;
  background-color: #e8e8e8;
  cursor: pointer;
}

.button7false:focus {
  margin: 0.5em;
  padding: 0.3em;
  border: none;
  border-radius: 0.3em;
  background-color: #f2f2f2;
  cursor: pointer;
}

.button7false:active {
  margin: 0.5em;
  padding: 0.3em;
  border: none;
  border-radius: 0.7em;
  background-color: #f2f2f2;
  cursor: pointer;
}

.button7true {
  margin: 0.5em;
  padding: 0.3em;
  border: none;
  border-radius: 0.3em;
  background-color: #ffd0c0;
  cursor: pointer;
}

.button7true:focus {
  margin: 0.5em;
  padding: 0.3em;
  border: none;
  border-radius: 0.3em;
  background-color: #f0e0d0;
  cursor: pointer;
}

.button7true:active {
  margin: 0.5em;
  padding: 0.3em;
  border: none;
  border-radius: 0.7em;
  background-color: #f0e0d0;
  cursor: pointer;
}

.button8false {
  margin: 0em;
  padding: 0.3em;
  border: none;
  border-radius: 0.3em;
  background-color: #e8e8e8;
  cursor: pointer;
}

.button8false:focus {
  margin: 0em;
  padding: 0.3em;
  border: none;
  border-radius: 0.3em;
  background-color: #f2f2f2;
  cursor: pointer;
}

.button8false:active {
  margin: 0em;
  padding: 0.3em;
  border: none;
  border-radius: 0.7em;
  background-color: #f2f2f2;
  cursor: pointer;
}

.button8true {
  margin: 0em;
  padding: 0.3em;
  border: none;
  border-radius: 0.3em;
  background-color: #ffd0c0;
  cursor: pointer;
}

.button8true:focus {
  margin: 0em;
  padding: 0.3em;
  border: none;
  border-radius: 0.3em;
  background-color: #f0e0d0;
  cursor: pointer;
}

.button8true:active {
  margin: 0em;
  padding: 0.3em;
  border: none;
  border-radius: 0.7em;
  background-color: #f0e0d0;
  cursor: pointer;
}

.permissionBox {
  padding: 1em 0.5em 0.5em 0.5em;
  border: 2px solid black;
  border-radius: 5px;
}

.permissionBoxTitle {
  position: absolute;
/*  z-index: 10001; */
  top: -0.8em;
  left: 1.5em;
}

/*
.permissionBoxTitleBorder {
  position: absolute;
  z-index: 10002;
  top: -1.5em;
  left: 1.5em;
  padding: 0.3em;
  border: 4px solid blue; 
  background-color: white;
}
*/

.concealer {
  width: 4px;
  height: 1em;
  background-color: white;
}

.bar1 {
  height: 2.2em;
  width: 100%;
  margin: 0.5em 0em;
  padding: 0em;
  border: none;
  border-radius: 0.5em;
  background-color: #c0d0ff;
  cursor: pointer;
}

.bar1:focus {
  height: 2.2em;
  width: 100%;
  margin: 0.5em 0em;
  padding: 0em;
  border: none;
  border-radius: 0.5em;
  background-color: #d0e0f0;
  cursor: pointer;
}

.bar1:active {
  height: 2.2em;
  width: 100%;
  margin: 0.5em 0em;
  padding: 0em;
  border: none;
  border-radius: 0.7em;
  background-color: #d0e0f0;
  cursor: pointer;
}

.bar2 {
  height: 2.2em;
  width: 100%;
  margin: 0.5em 0em;
  padding: 0em;
  border: none;
  border-radius: 0.5em;
  background-color: #ffd0c0;
  cursor: pointer;
}

.bar2:focus {
  height: 2.2em;
  width: 100%;
  margin: 0.5em 0em;
  padding: 0em;
  border: none;
  border-radius: 0.5em;
  background-color: #f0e0d0;
  cursor: pointer;
}

.bar2:active {
  height: 2.2em;
  width: 100%;
  margin: 0.5em 0em;
  padding: 0em;
  border: none;
  border-radius: 0.7em;
  background-color: #f0e0d0;
  cursor: pointer;
}

.bar3 {
  height: 2.2em;
  width: 100%;
  margin: 0.5em 0em;
  padding: 0em;
  border: none;
  border-radius: 0.5em;
  background-color: #ffffb0;
  cursor: pointer;
}

.bar3:focus {
  height: 2.2em;
  width: 100%;
  margin: 0.5em 0em;
  padding: 0em;
  border: none;
  border-radius: 0.5em;
  background-color: #f0f0c0;
  cursor: pointer;
}

.bar3:active {
  height: 2.2em;
  width: 100%;
  margin: 0.5em 0em;
  padding: 0em;
  border: none;
  border-radius: 0.7em;
  background-color: #f0f0c0;
  cursor: pointer;
}

.label1Unfilled {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  padding: 0em 0.5em;
}

.invisibleCheckBox:checked + .label1Unfilled {
  font-size: 0.5em;
}

.label1Filled {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  padding: 0em 0.5em;
  font-size: 0.5em;
}

.invisibleCheckBox {
  display: none;
}

.invisibleCheckBox:checked +.box1 {
  background-color: rgba(183,183,183,0.5);
}

.invisibleCheckBox:checked +.box2 {
  background-color: rgba(183,183,183,0.5);
}

.invisibleCheckBox:checked +.box3 {
  background-color: rgba(183,183,183,0.5);
}

.invisibleCheckBox:checked +.box4 {
  background-color: rgba(183,183,183,0.5);
}

.monacardArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 800px;
  width: 560px;
  border: 1px solid black;
}

.monacardArea2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 280px;
  border: 1px solid black;
}

.monacardArea3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 240px;
  width: 168px;
  margin: 0em 0em 0em 0.25em;
  border: 1px solid #c0d0ff;
}

@media screen and (max-width:1080px) {
  .monacardAreaSp {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    border: 1px solid black;
  }
}

.aggregatedMonablessItemBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5em;
  border: 2px outset #c0d0ff;
}

.selectbox1 {
  width: 272px;
  height: 2.2em;
  margin: 0.5em 2px;
  font-size: 1em;
  border-top: none;
  border-bottom: 1px solid #803030;
  border-left: none;
  border-right: none;
  border-radius: 0.3em;
  background-color: rgba(230,230,240,0.5);
}

.selectbox1:focus {
  width: 272px;
  height: 2.2em;
  margin: 0.5em 2px;
  font-size: 1em;
  border-top: none;
  border-bottom: 1px solid #803030;
  border-left: none;
  border-right: none;
  border-radius: 0.3em;
  background-color: rgba(183,183,183,0.5);
}

/*
.popUpCheckBox, .popUp {
  display: none;
}

.popUpCheckBox:checked + .popUp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
*/

.popup2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.invisible {
  display: none;
}

.popUp {
  display: none;
}

.invisibleCheckBox:checked + .popUp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closePopUp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: block;
  background: rgba(0,0,0,.3);
  cursor: pointer;
}

.popUpWrap {
  position: relative;
  max-height: 90%;
/*  max-width: 85%; */
  max-width: 600px;
  z-index: 10001;
  border-radius: 10px;
  background: #fff;
  padding: 1.5em 1em;
  word-wrap: break-word;
  overflow-y: auto;
}

.topRightClose {
  position: absolute;
  top: 0em;
  right: 0em;
  width: 2em;
  height: 2em;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 10px;
  background-color: transparent;
  color: #803030;
  font-size: 1em; 
}

.notification {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 1em;
  margin: 4px 1em 0em;
  padding: 0em 1em 0.1em;
  border-radius: 10px;
  background: #f3f3f3;
}

/* ------------------------------------------ */

.border {
  border: 1px solid green;
}

.borderBlue {
  border: 1px solid #d0e0f0;
  border-radius: 3px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flexRowNowrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.flexColumnNowrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.alignItemsCenter {
  align-items: center;
}

.alignItemsFlexStart {
  align-items: flex-start;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyContentFlexStart {
  justify-content: flex-start;
}

.justifyContentSpaceAround {
  justify-content: space-around;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

.height100 {
  height: 100%;
}

.height10 {
  height: 10em;
}

.width100 {
  width: 100%;
}

.width11p5em {
  width: 11.5em;
}

.size100 {
  height: 100%;
  width: 100%;
}

.size2x2 {
  height: 2.2em;
  width: 2.2em;
}

.size1p5x1p5 {
  height: 1.5em;
  width: 1.5em;
}

.margin0 {
  margin: 0em;
}

.margin0p5 {
  margin: 0.5em;
}

.margin2 {
  margin: 2em;
}

.marginSide1 {
  margin: 0em 1em;
}

.marginSide0p5 {
  margin: 0em 0.5em;
}

.marginTopBottom1 {
  margin: 1em 0em;
}

.marginTopBottom0p5 {
  margin: 0.5em 0em;
}

.marginTop1 {
  margin-top: 1em;
}

.marginBottom0p5 {
  margin-bottom: 0.5em;
}

.marginTopBottom1px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.marginTop1 {
  margin: 1em 0em 0em 0em;
}

.marginBottom1 {
  margin: 0em 0em 1em 0em;
}

.padding0 {
  padding: 0;
}

.paddingTopBottom0p5 {
  padding: 0.5em 0em;
}

.paddingSide0p5 {
  padding: 0em 0.5em;
}

.borderNone {
  border: none;
}

.borderRadius0p3 {
  border-radius: 0.3em;
}

.backgroundColorAqour {
  background-color: #c0d0ff;
}

.backgroundColorWhite {
  background-color: white;
}

.colorRed {
  color: red;
}

.wordWrapBreakWord {
  word-wrap: break-word;
}

.resizeNone {
  resize: none;
}

.textCenter {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: Right;
}

.font0p7 {
  font-size: 0.7em;
}

.font3 {
  font-size: 3em;
}

.zIndex10 {
  z-index: 10;
}

.whiteSpacePreWrap {
  white-space: pre-wrap;
}

.messageFade-enter-active {
  z-index: 10001;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  color: #803030;
  opacity: 0;
  transition: opacity 2000ms ease-out 3000ms;
  padding: 0.5em 1em;
  background-color: #ffe3b3;
  border-radius: 5px;
}

.messageFade-enter-done {
  display: none;
  color: #803030;
  opacity: 0;
}

.messageFade-exit {
  display: none;
  color: #803030;
  opacity: 0;
}

.marquee-enter {
  display: inline-block;
  padding-left: 100%;
  transform: translateX(0);
}

.marquee-enter-active {
  padding-left: 0%;
  transform: translateX(-100%);
  transition-property: all;
  transition-duration: var(--duration);
/*  transition-timing-function: linear; */
  transition-timing-function: cubic-bezier(0, 0, 0.8, 0.5);
}

.marquee-exit {
  display: none;
}

.marquee-exit-done {
  display: none;
}

.marqueeBox {
  position: absolute;
  top: 80%;
  left: 0;
  width: 100%;
  z-index: 300;
  white-space: nowrap;
  font-size: 2em;
  color: white;
}

.line-enter {
  opacity: 1;
}

.line-enter-active {
  opacity: 0;
  transition: opacity var(--duration) ease-out var(--delay);
}

.line-exit {
  opacity: 0;
}

.line-exit-done {
  opacity: 0;
}

.speechBaloon {
  position: absolute;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5em;
  border-radius: 2em;
  border: 2px solid black;
  background: white;
}

.outerLineBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
  background-color: transparent;
  text-align: center;
  font-size: 1em;
  color: black;
}

.upperLineBoxOff {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 204;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.upperLineBox {
  position: absolute;
  top: -10%;
  left: -10%;
  width: 120%;
  height: 120%;
  z-index: 205;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.zIndex205 {
  z-index: 205;
}

.button6 {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #8090ff;
  background-color: rgba(192,208,255,0.5);
  cursor: pointer;
}

.button6Dummy {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: none;
  background-color: transparent;
}

.lineBox0 {
  position: absolute;
  top: 3%;
  left: 66%;
  width: 28%;
  min-height: 40%;
  max-height: 90%;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5em;
  border-radius: 2em;
  border: 2px solid black;
  text-align: center;
  font-size: 1em;
  color: black;
  background: white;
/*  overflow: hidden; */
}

.triangle {
  content: '';
  position: absolute;
  top: 80%;
  left: -1.5em;
  width: 0em;
  height: 0em;
  transform: translateY(-50%);
  z-index: 201;
  border-style: solid;
  border-color: transparent black transparent transparent;
  border-width: 1.5em 1.5em 1.5em 0em;
}

.triangleTop:before {
  content: '';
  position: absolute;
  top: -1.5em;
  left: var(--positionX);
  width: 0em;
  height: 0em;
  transform: translateX(-50%);
  z-index: 201;
  border-style: solid;
  border-color: transparent transparent black transparent;
  border-width: 0em 1.5em 1.5em 1.5em;
}

.triangleTop:after {
  content: '';
  position: absolute;
  top: calc(-1.5em + 2px);
  left: var(--positionX);
  width: 0em;
  height: 0em;
  transform: translateX(-50%);
  z-index: 202;
  border-style: solid;
  border-color: transparent transparent white transparent;
  border-width: 0em calc(1.5em - 0px) calc(1.5em - 0px) calc(1.5em - 0px);
}

.triangleRight:before {
  content: '';
  position: absolute;
  top: var(--positionY);
  right: -1.5em;
  width: 0em;
  height: 0em;
  transform: translateY(-50%);
  z-index: 201;
  border-style: solid;
  border-color: transparent transparent transparent black;
  border-width: 1.5em 0em 1.5em 1.5em;
}

.triangleRight:after {
  content: '';
  position: absolute;
  top: var(--positionY);
  right: calc(-1.5em + 2px);
  width: 0em;
  height: 0em;
  transform: translateY(-50%);
  z-index: 202;
  border-style: solid;
  border-color: transparent transparent transparent white;
  border-width: calc(1.5em - 0px) 0em calc(1.5em - 0px) calc(1.5em - 0px);
}

.triangleBottom:before {
  content: '';
  position: absolute;
  bottom: -1.5em;
  left: var(--positionX);
  width: 0em;
  height: 0em;
  transform: translateX(-50%);
  z-index: 201;
  border-style: solid;
  border-color: black transparent transparent transparent;
  border-width: 1.5em 1.5em 0em 1.5em;
}

.triangleBottom:after {
  content: '';
  position: absolute;
  bottom: calc(-1.5em + 2px);
  left: var(--positionX);
  width: 0em;
  height: 0em;
  transform: translateX(-50%);
  z-index: 202;
  border-style: solid;
  border-color: white transparent transparent transparent;
  border-width: calc(1.5em - 0px) calc(1.5em - 0px) 0em calc(1.5em - 0px);
}

.triangleLeft:before {
  content: '';
  position: absolute;
  top: var(--positionY);
  left: -1.5em;
  width: 0em;
  height: 0em;
  transform: translateY(-50%);
  z-index: 201;
  border-style: solid;
  border-color: transparent black transparent transparent;
  border-width: 1.5em 1.5em 1.5em 0em;
}

.triangleLeft:after {
  content: '';
  position: absolute;
  top: var(--positionY);
  left: calc(-1.5em + 2px);
  width: 0em;
  height: 0em;
  transform: translateY(-50%);
  z-index: 202;
  border-style: solid;
  border-color: transparent white transparent transparent;
  border-width: calc(1.5em - 0px) calc(1.5em - 0px) calc(1.5em - 0px) 0em;
}

.layerFrame {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.relative {
  position: relative;
}

.overflowHidden {
  overflow: hidden;
}

.textShadow {
  text-shadow:
    black 2px 0px,  black -2px 0px,
    black 0px -2px, black 0px 2px,
    black 2px 2px , black -2px 2px,
    black 2px -2px, black -2px -2px,
    black 1px 2px,  black -1px 2px,
    black 1px -2px, black -1px -2px,
    black 2px 1px,  black -2px 1px,
    black 2px -1px, black -2px -1px;
}

.marquee_escape {
  position: absolute;
  top: 80%;
  left: 0;
/*  display: inline-block; */
/*  line-height: 60px; */
  padding-left: 100%;
  white-space: nowrap;
  color: white;
  font-size: 2em;
  animation: animate-marquee var(--second) linear infinite;
}

@keyframes animate-marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/*
.closePopUp {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.3);
  z-index: 10000;
  cursor: pointer;
}

.popUpWrap {
  position: relative;
  max-width: 85%;
  padding: 1em 1em;
  background: #fff;
  border-radius: 10px;
  z-index: 10001;
  word-wrap: break-word;
}
*/

/* ------------------------------------------ */

@media screen and (max-width:1080px) {
  .widthSp {
    width: 96vw;
  }

  .width90Sp {
    width: 90vw;
  }

  .widthWithButton1Sp {
    width: calc(96vw - 3.4em);
  }

  .widthWithButton1NarrowSp {
    width: calc(92vw - 3.4em);
  }
}

@media screen and (max-width:1080px) {
  .maxWidth30Sp {
    max-width: 30%;
  }

  .width50Sp {
    width: 50%;
  }

  .width100Sp {
    width: 100%;
  }

  .widthAutoSp {
    width: auto;
  }

  .height2emSp {
    height: 2em;
  }

  .flexColumnNowrapSp {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .justifyContentSpaceAroundSp {
    justify-content: space-around;
  }

  .justifyContentFlexEndSp {
    justify-content: flex-end;
  }

  .marginSide0p2Sp {
    margin-left: 0.2em;
    margin-right: 0.2em;
  }

  .wordWrapBreakWordSp {
    word-wrap: break-word;
  }
}

